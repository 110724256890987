import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import { Col, Button, Container, Row } from 'react-bootstrap';



import Modal from 'react-bootstrap/Modal';

type State = {

};

export class Home extends Component<State> {
  state: State = {

  };


  render() {
    return (
      <div>

        <section className="faithminus-section1">
         
          <Row>
              <Col sm={6} className="banner-part">
                <h2 className="fm-color-txt" >Era Swap</h2>
                <h1 className="fm-color-bold">Faith Minus</h1>
                <div className="btm-fm-border"></div>
                <p className="era-fm-txt"><strong>Era Swap Court</strong> is a Decentralized Dispute Management System
               <br /> for Multiple utility Platforms of Era Swap Ecosystem.
               <br /> It is built on the Blockchain dispute resolution layer which makes it
               <br /> Fast, Open and affordable Dispute resolution for all.</p>
               <div className ="rd-btn-sect1"><Link to="" className="rd-link">Raise Dispute  </Link></div>
              </Col>
              <Col sm={6} className="banner">
              {/* <div className="skewed">
              <img className="monument-img" src="./images/monument.jpg" />
              </div> */}
               
              </Col>
            </Row>
        </section>
        <section className="faithminus-section2 ">
          <Container className="pdt60 pdb60">
          
          <h4 className="sect2-head mt20 mb60">Why Era Swap Faith Minus?</h4>
            <Row>
              <Col sm={4}>
                <div className="flex-fm-center"><img className="fm-img-circle" src="./images/member.png" /></div>
                <h5 className="sect2-fm-head">Apply a Conservators</h5>
                <p>Conservators are members who will resolve the
                dispute arising on the platform and also review
                the Power Token allocation in Swappers Wall or
                    other Platform of Era Swap Ecosystem.</p>
              </Col>
              <Col sm={4}>
                <div className="flex-fm-center"><img className="fm-img-circle" src="./images/peer-to-peer.png" /></div>
                <h5 className="sect2-fm-head">Peers to Peers Dispute Resolution</h5>
                <p>A peer to peer platform that uses anonymous
                Dispute resolvers dedicated to resolve the
                  Dispute on the Network</p>
              </Col>

              <Col sm={4}>

                <div className="flex-fm-center"><img className="fm-img-circle" src="./images/gear.png" /></div>
                <h5 className="sect2-fm-head">Cost Optimisation</h5>
                <p>The Decentralized approach of Era Swap Faith
                Minus reduces the cost by 70% due to
                automation & digital claims settlement.</p>
              </Col>
              
              <Col sm={4}>
                <div className="flex-fm-center"><img className="fm-img-circle" src="./images/affordclock.png" /></div>
                <h5 className="sect2-fm-head">Affordable & Quick Turnaround time</h5>
                <p>The Disputed parties can now open dispute with
                user friendly Dispute Management system and
                get the resolution as per the Turnaround
                    24hrs to 96 Hours.</p>
              </Col>
              <Col sm={4}>
                <div className="flex-fm-center"><img className="fm-img-circle" src="./images/ecosystem.png" /></div>
                <h5 className="sect2-fm-head">An Unbiased Ecosystem</h5>
                <p>The Decentralized Dispute Management System
                is corruption free due to the Era Swap
                blockchain, which ensures immutability and
                transparency, as well as automation of
                  transactions and claims</p>
              </Col>

              <Col sm={4}>

                <div className="flex-fm-center"><img className="fm-img-circle" src="./images/resolution.png" /></div>
                <h5 className="sect2-fm-head">Multi Layer Resolution Mechanism</h5>
                <p>The Era Swap Faith Minus provides the disupted
                parties complete transparency & corruption free
                flexibility to counter & settle dispute at multi
                  layer resolution approach.</p>
              </Col>
            
            </Row>
          </Container>
        </section>

        <section className="faithminus-section3">
         
          <Row>
               <Col sm={6} className="curatorbanner"> </Col>
                <Col sm={6} className="curator-part">
                <h2  className="fm-color-txt shft-right">Join as a</h2>
                <h1 className="fm-color-bold shft-right">Curator</h1>
 
                <div className="border-right">
                <div className="btm-fm-border"></div>
                </div>
                <p className="rt-desc-curator">Become a Conservator, earn & resolve dispute for Decentralized application</p>
             <p className="rt-desc-curator"><strong>Every Conservator </strong>, who stakes Era Swap (ES) in TimeAlly
                        Curator Plan (TCP)for 6 months , is allowed to participate in the
                        Era Swap Faith Minus. The share of the fees, the Conservator
                        will get as per the Workpool depends on the number of Tokens
                        he/she staked and predominantly on his activity in the Era
                        Swap Faith Minus. This means that the Curators are rewarded
                        for their efforts in the Era Swap Faith Minus.</p>
                        <div className="flex-fm-end">
                <div className="rt-btn-curator"><Link to="" className="rd-link">Join as a Curator</Link></div>
                </div>
              </Col>
            </Row>
        </section>


        <section className="faithminus-section4">
          <Container>
          <Row>
               <Col sm={12}> 
               <h4 className="sect2-head mt40 mb30">Why Era Swap Faith Minus?</h4>
               <div className="col-md-12" id="logo-carsoul">
                      <div className="owl-carousel owl-theme">
                            <div className="item">
                            <img src="images/logo/esn-logo.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/swapperwall.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/timeally.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/eraswapwallet.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/timeswappers.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/dayswappers.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/buzcafe.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/eraswapacademy.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/betdeex.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/comp.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/comp-lb.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/faithminus.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/vof.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/certidapp.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/bookingdapp.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/charitydapp.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/rentingdapp.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/curedapp.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/dateswappers.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/coupondapp.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/recyclingdapp.png"/>
                            </div>
                            <div className="item">
                            <img src="images/logo/poolingdapp.png"/>
                            </div>
                        </div>
					       		</div>
                </Col>
              </Row>
          </Container>
        </section>


</div>

          
          
       
    );
  }
}
