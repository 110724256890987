import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './JoinCourt.css';
import { Col, Button, Container, Row } from 'react-bootstrap';
import Rater from 'react-rater';
import 'react-rater/lib/react-rater.css';
import Modal from 'react-bootstrap/Modal';
type State = {
rate: string;
showModal: boolean;
rateModal: boolean;
};
export class JoinCourt extends Component
<State>
{
state: State = {
rate: '',
showModal: false,
rateModal: false,
};
handleClose = () => {
this.setState({
showModal: false,
})
}
render() {
return (
<div>
   <div className="wrapper-container pdt80">
      <section className="faithminus-section1">
         <Container className="pdt60 pdb60">
            <Row>
               <Col sm={12} className="project-tab">
               
               <nav>
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                     <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#active-dispute" role="tab" aria-controls="active-dispute" aria-selected="true">Level 1</a>
                     <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#pending-disputes" role="tab" aria-controls="pending-disputes" aria-selected="false"> Level 2 <i className="fa fa-lock" aria-hidden="true"></i></a>
                     <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#complete-dispute" role="tab" aria-controls="complete-dispute" aria-selected="false">Level 3 <i className="fa fa-lock" aria-hidden="true"></i></a>
                     
                  </div>
               </nav>
               <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="active-dispute" role="tabpanel" aria-labelledby="nav-home-tab">
                    <form>

                       <div className="form-row">
                         <div className="form-group col-md-6">
                           <label>Your Name*</label>
                           <input type="email" className="form-control" id="" placeholder=""/>
                         </div>
                         <div className="form-group col-md-6">
                           <label>Your Wallet Address*</label>
                           <input type="" className="form-control" id="" placeholder=""/>
                         </div>
                       </div>

                        <div className="form-row">
                         <div className="form-group col-md-6">
                           <label>Defendant Name</label>
                           <input type="email" className="form-control" id="" placeholder=""/>
                         </div>
                         <div className="form-group col-md-6">
                           <label>Defendant Wallet Address</label>
                           <input type="" className="form-control" id="" placeholder=""/>
                         </div>
                       </div>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Dispute on which platform</label>
                            <select id="inputState" className="form-control">
                                <option selected>Choose...</option>
                                <option>...</option>
                              </select>
                          </div>
                          <div className="form-group col-md-6">
                            <label>Turn Around Time</label>
                            <select id="inputState" className="form-control">
                                <option selected>Choose...</option>
                                <option>...</option>
                              </select>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Curators required</label>
                            <select id="inputState" className="form-control">
                                <option selected>Choose...</option>
                                 <option>1</option>
                                 <option>2</option>
                                 <option>3</option>
                              </select>
                          </div>
                          
                        </div>
                          <div className="form-group ">
                              <label>Title</label>
                              <input type="email" className="form-control" id="" placeholder=""/>
                           </div>
                           <div className="form-group ">
                              <label>Description</label>
                              <textarea className="form-control" id="" ></textarea>
                           </div>

                            <div className="form-group ">
                           <div className="custom-file">
                             <input type="file" className="custom-file-input" id="customFile"/>
                             <label className="custom-file-label" >Attach</label>
                             <p className="mt10"> Attach required screenshots of conversation of you and defendant, which includes claim settlement or Counter
                              offer information.</p>
                           </div>
                           </div>
                         
                         
                     
                       <a href="" data-toggle="modal" data-target="#disputeaccept" className="btn btn-primary btn-lg">Submit</a>
                     </form>

                  </div>


                  <div className="tab-pane fade" id="pending-disputes" role="tabpanel" aria-labelledby="nav-profile-tab">
                     <Row className="mt20">Level 2 </Row>
                  </div>


                  <div className="tab-pane fade" id="complete-dispute" role="tabpanel" aria-labelledby="nav-contact-tab">
                     <Row className="mt20">Level 3 </Row>
                  </div>



                  
               </div>
               </Col>
            </Row>
         </Container>
      </section>
  </div>


   <div className="modal fade com-modal" id="disputeaccept" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
         <div className="modal-content">
           <button type="button" className="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
               </button>
            <div className="modal-body text-center mt20 mb20">
               <i className="fa fa-check-circle text-themecolor fa-5x" aria-hidden="true"></i>
               <h3 className="text-themecolor">Your Dispute is
                  successfully submitted</h3>
            </div>
         </div>
      </div>
   </div>

</div>
);
}
}