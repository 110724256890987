import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './CuratorProceedLevelTwo.css';
import { Col, Button, Container, Row } from 'react-bootstrap';
import Rater from 'react-rater';
import 'react-rater/lib/react-rater.css';
import Modal from 'react-bootstrap/Modal';
type State = {
rate: string;
showModal: boolean;
rateModal: boolean;
};
export class CuratorProceedLevelTwo extends Component
<State>
{
state: State = {
rate: '',
showModal: false,
rateModal: false,
};
handleClose = () => {
this.setState({
showModal: false,
})
}
render() {
return (
<div>
   <div className="wrapper-container pdt80">
      <section className="faithminus-section1">
         <Container className="pdt60 pdb60">

            <Row>
               <Col sm={12} className="single-page gray-bg">
                   <Row className="mt10">
                           <Col sm={4} className="">
                             <div className="card card-without-border">
                                <ul className="list-group list-group-flush ">
                                   <li className="list-group-item d-flex justify-content-between align-items-center">
                                      Title : 
                                      <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                   </li>
                                   <li className="list-group-item d-flex justify-content-between align-items-center">
                                      Disputed on :
                                      <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                   </li>
                                   <li className="list-group-item d-flex justify-content-between align-items-center">
                                      Created On :
                                      <span className="badge badge-primary badge-pill"> 23-05-2020</span>
                                   </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                      <h6>Level 2 Dispute</h6>
                                   </li>
                                   <li className="list-group-item d-flex justify-content-between align-items-center">
                                      Result Time:
                                      <span className="badge badge-primary badge-pill">26-05-2020 I 9:00 am</span>
                                   </li>
                                 
                                  
                                </ul>
                               
                             </div>
                           </Col>
                        </Row>
                  <Row className="mt30">
                     <Col md={12} className="">
                     <h5 className="text-themecolor">Curators Involved </h5>
                     </Col>
                  </Row>
                  <Row className="mt10">
                      <Col md={3} className=" mt10 mb10">
                           <div className="card card-without-border text-center curator-box">
                              <img className="curator-img" src="./images/user.png" alt=""/>
                              <h6 className="text-themecolor">Jessica Does </h6>
                              <div className="star-rating">
                                 <input type="radio" id="5-stars" name="rating" value="5" />
                                 <label  className="star">&#9733;</label>
                                 <input type="radio" id="4-stars" name="rating" value="4" />
                                 <label  className="star">&#9733;</label>
                                 <input type="radio" id="3-stars" name="rating" value="3" />
                                 <label className="star">&#9733;</label>
                                 <input type="radio" id="2-stars" name="rating" value="2" />
                                 <label className="star">&#9733;</label>
                                 <input type="radio" id="1-star" name="rating" value="1" />
                                 <label className="star">&#9733;</label>
                              </div>
                              <div className="col-md-12">
                                    <p><a href="" className="btn btn-outline-primary  btn-block  btn-sm mb10" data-toggle="modal" data-target="#resolution">View Profile</a></p>
                                   <p> <a href="" className="btn btn-primary btn-sm  btn-block mt10 mb10" data-toggle="modal" data-target="#givereview">Give Review</a></p>
                              </div>
                       </div>
                     </Col>
                      <Col md={3} className=" mt10 mb10">
                           <div className="card card-without-border text-center curator-box">
                              <img className="curator-img" src="./images/user.png" alt=""/>
                              <h6 className="text-themecolor">Jessica Does </h6>
                              <div className="star-rating">
                                 <input type="radio" id="5-stars" name="rating" value="5" />
                                 <label  className="star">&#9733;</label>
                                 <input type="radio" id="4-stars" name="rating" value="4" />
                                 <label  className="star">&#9733;</label>
                                 <input type="radio" id="3-stars" name="rating" value="3" />
                                 <label className="star">&#9733;</label>
                                 <input type="radio" id="2-stars" name="rating" value="2" />
                                 <label className="star">&#9733;</label>
                                 <input type="radio" id="1-star" name="rating" value="1" />
                                 <label className="star">&#9733;</label>
                              </div>
                              <div className="col-md-12">
                                    <p><a href="" className="btn btn-outline-primary  btn-block  btn-sm mb10" data-toggle="modal" data-target="#resolution">View Profile</a></p>
                                   <p> <a href="" className="btn btn-primary btn-sm  btn-block mt10 mb10" data-toggle="modal" data-target="#givereview">Give Review</a></p>
                              </div>
                       </div>
                     </Col>
                      <Col md={3} className=" mt10 mb10">
                           <div className="card card-without-border text-center curator-box">
                              <img className="curator-img" src="./images/user.png" alt=""/>
                              <h6 className="text-themecolor">Jessica Does </h6>
                              <div className="star-rating">
                                 <input type="radio" id="5-stars" name="rating" value="5" />
                                 <label  className="star">&#9733;</label>
                                 <input type="radio" id="4-stars" name="rating" value="4" />
                                 <label  className="star">&#9733;</label>
                                 <input type="radio" id="3-stars" name="rating" value="3" />
                                 <label className="star">&#9733;</label>
                                 <input type="radio" id="2-stars" name="rating" value="2" />
                                 <label className="star">&#9733;</label>
                                 <input type="radio" id="1-star" name="rating" value="1" />
                                 <label className="star">&#9733;</label>
                              </div>
                              <div className="col-md-12">
                                    <p><a href="" className="btn btn-outline-primary  btn-block  btn-sm mb10" data-toggle="modal" data-target="#resolution">View Profile</a></p>
                                   <p> <a href="" className="btn btn-primary btn-sm  btn-block mt10 mb10" data-toggle="modal" data-target="#givereview">Give Review</a></p>
                              </div>
                       </div>
                     </Col>




                      <Col md={3} className=" mt10 mb10">
                           <div className="card card-without-border text-center curator-box">
                              <img className="curator-img" src="./images/user.png" alt=""/>
                              <h6 className="text-themecolor">Jessica Does </h6>
                              <div className="star-rating">
                                 <input type="radio" id="5-stars" name="rating" value="5" />
                                 <label  className="star">&#9733;</label>
                                 <input type="radio" id="4-stars" name="rating" value="4" />
                                 <label  className="star">&#9733;</label>
                                 <input type="radio" id="3-stars" name="rating" value="3" />
                                 <label className="star">&#9733;</label>
                                 <input type="radio" id="2-stars" name="rating" value="2" />
                                 <label className="star">&#9733;</label>
                                 <input type="radio" id="1-star" name="rating" value="1" />
                                 <label className="star">&#9733;</label>
                              </div>
                              <div className="col-md-12">
                                    <p><a href="" className="btn btn-outline-primary  btn-block  btn-sm mb10" data-toggle="modal" data-target="#resolution">View Profile</a></p>
                                   <p> <a href="" className="btn btn-primary btn-sm  btn-block mt10 mb10" data-toggle="modal" data-target="#givereview">Give Review</a></p>
                              </div>
                       </div>
                     </Col>
                  </Row>
                  <Row className="mt30">
                     <Col md={12} className="">
                     <a href="" className="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#resolution">Back</a>
                     </Col>
                  </Row>
                   <Row className="mt30">
                     <Col md={12} className="">
                     <a href="" className="btn btn-primary btn-block text-left btn-sm" data-toggle="modal" data-target="#resolution">
                      <small>Not Satisfied ?</small> 
                      <h6>Click here to proceed to Level 2 <i className="fa fa-long-arrow-right" aria-hidden="true"></i></h6>
                     </a>
                     </Col>
                  </Row>

               </Col>
            </Row>
         </Container>
      </section>
   </div>


   <div className="modal fade com-modal" id="givereview" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
         <div className="modal-content">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
               </button>
            <div className="modal-body mt10 mb10">
                   <div className="mt10 mb10 curator-box">
                         <div className="media mb00">
                           <a className="media-left media-middle ml10 mr10 " href="#">
                               <img className="curator-img " src="./images/user.png" alt=""/>
                           </a>

                           <div className="media-body">
                             <h6 className="media-heading font-weight-bold text-themecolor mt20">Jessica Does</h6>

                            </div>
                         </div>
                       
                        <div className="form-group ml10 mr10">
                            <p className="text-success">Give your review here based on your experience</p>
                         

                          <div className="star-rating m-0">
                                 <input type="radio" id="5-stars" name="rating" value="5" />
                                 <label  className="star">&#9733;</label>
                                 <input type="radio" id="4-stars" name="rating" value="4" />
                                 <label  className="star">&#9733;</label>
                                 <input type="radio" id="3-stars" name="rating" value="3" />
                                 <label className="star">&#9733;</label>
                                 <input type="radio" id="2-stars" name="rating" value="2" />
                                 <label className="star">&#9733;</label>
                                 <input type="radio" id="1-star" name="rating" value="1" />
                                 <label className="star">&#9733;</label>
                              </div>
                          <textarea className="form-control" id="" ></textarea><br/>
                          <a href="" data-dismiss="modal" aria-label="Close" className="btn btn-primary btn-sm mt10 mb10" data-toggle="modal" data-target="#givereview">Submit</a>
                        </div>
                  </div>
            </div>
         </div>
      </div>
   </div>
 </div>
);
}
}