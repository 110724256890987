import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './RequestDispute.css';
import { Col, Button, Container, Row } from 'react-bootstrap';
import Rater from 'react-rater';
import 'react-rater/lib/react-rater.css';
import Modal from 'react-bootstrap/Modal';
type State = {
rate: string;
showModal: boolean;
rateModal: boolean;
};
export class RequestDispute extends Component
<State>
{
state: State = {
rate: '',
showModal: false,
rateModal: false,
};
handleClose = () => {
this.setState({
showModal: false,
})
}
render() {
return (
<div>
   <div className="wrapper-container pdt80">
      <section className="faithminus-section1">
         <Container className="pdt60 pdb60">
            <Row>
               <Col sm={12} className="single-page gray-bg">
               <Row className="mt10">
                  <Col md={12} className="">
                  <h5 className="text-themecolor">Request Dispute</h5>
                  </Col>
               </Row>
               <Row className="mt10">
                  <Col md={8} className="">
                  <div className="card card-without-border">
                     <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                           Title : 
                           <span className="badge badge-dark badge-pill">Work Submitted Incomplete</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                           Disputed on :
                           <span className="badge badge-dark badge-pill"> TimeSwappers</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                           Created On :
                           <span className="badge badge-dark badge-pill"> 23-05-2020</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                           Complanant Name :
                           <span className="badge badge-dark badge-pill">Jessica Does</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                           Complanant Wallet Address :
                           <span className="badge badge-dark badge-pill">xx4f52fa4ad56fas56fikfhbz</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                           Defendant Name :
                           <span className="badge badge-dark badge-pill">Mike Hannigan</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                           Defendant Wallet Address :
                           <span className="badge badge-dark badge-pill">xx4f52fa4ad56fas56fikfhbz</span>
                        </li>
                     </ul>
                     <div className="col-md-12 mt20">
                        <h6 className="text-themecolor">Description :</h6>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                     </div>
                  </div>
                  </Col>
                  <Col md={4} className="">
                  <div className="col-md-12 mt10 mb10">
                     <h4 className="text-themecolor"> Level 1 Dispute</h4>
                     <Row>
                        <Col md={12} className="mt10 mb10">
                        <a href="" className="btn btn-success btn-block text-white btnplg" data-toggle="modal" data-target="#disputeaccept">Accept</a>
                        </Col>
                        <Col md={12} className="mt10 mb10">
                        <a href="" className="btn btn-outline-danger btn-block btnplg">Decline</a></Col>
                     </Row>
                  </div>
                  </Col>
               </Row>
               </Col>
               <Col sm={12} className="single-page gray-bg mt20">
               <h6 className="text-themecolor">Attachments </h6>
               <div className="col-md-12  mt20">
                  <div className="border-style-img">
                     <img className="faithminus-plus-Img img-fluid" src="./images/faithminuslogo.png" alt=""/>
                  </div>
                  <div className="border-style-img">
                     <img className="faithminus-plus-Img img-fluid" src="./images/faithminuslogo.png" alt=""/>
                  </div>
                  <div className="border-style-img">
                     <img className="faithminus-plus-Img img-fluid" src="./images/faithminuslogo.png" alt=""/>
                  </div>
                  <div className="border-style-img">
                     <img className="faithminus-plus-Img img-fluid" src="./images/faithminuslogo.png" alt=""/>
                  </div>
                  <div className="border-style-img">
                     <img className="faithminus-plus-Img img-fluid" src="./images/faithminuslogo.png" alt=""/>
                  </div>
               </div>
               </Col>
               <Col sm={12} className="single-page gray-bg mt20">
               <h6 className="text-themecolor">Your Resolution </h6>
               <div className="form-group">
                 
                  <textarea className="form-control" id="exampleFormControlTextarea1"></textarea>
               </div>
               <a href="" className="btn btn-primary text-white" data-toggle="modal" data-target="#resolution">Submit</a>
               </Col>
            </Row>
         </Container>
      </section>
   </div>



 
<div className="modal fade com-modal" id="resolution" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
       
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body text-center mt20 mb20">
         <i className="fa fa-check-circle text-themecolor fa-5x" aria-hidden="true"></i>
        <h3 className="text-themecolor">Your resolution is successfully submitted </h3>
      </div>
      
    </div>
  </div>
</div>

 
<div className="modal fade com-modal" id="disputeaccept" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
       
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body text-center mt20 mb20">
         <i className="fa fa-check-circle text-themecolor fa-5x" aria-hidden="true"></i>
        <h3 className="text-themecolor">The Dispute is <br/>Successfully Accepted </h3>
      </div>
      
    </div>
  </div>
</div>


</div>
);
}
}