import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { Col, Button, Container, Row, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

type State = {

};

export class Footer extends Component<State> {
  state: State = {

  };


  render() {
    return (
      <div>

        <div className='Footer-container pdb20'>
         
          <section className="footers pb-3">
            <div className="container pt-5">
              <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-6 footers-one">
                  <div className="footers-logo">
                     <img src="./images/faithminuslogo.png" height="120" className="d-inline-block align-top " alt="" />
                  </div>
                  <div className="footers-info mt-3">
                    <p>Date Swappers is a modern, unique and decentralized dating app that connects you with the world and lets you meet new people and new business .....</p>
                  </div>
                  <div className="footer-social-flex">
                    <ul className="social-menu">
                      <li><a href="https://www.facebook.com/eraswap" target="_blank"><i className="fa fa-facebook"></i></a></li>
                      <li><a href="https://www.linkedin.com/company/eraswap/" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                      <li><a href="https://www.instagram.com/eraswap/?hl=en" target="_blank"><i className="fa fa-instagram"></i></a> </li>
                      <li><a href="https://twitter.com/EraSwapTech" target="_blank"><i className="fa fa-twitter"></i></a></li>
                      <li><a href="https://medium.com/@eraswap" target="_blank"><i className="fa fa-medium"></i></a></li>
                      <li><a href="https://eraswap.tumblr.com/" target="_blank"><i className="fa fa-tumblr"></i></a></li>
                      <li><a href="https://t.me/eraswap" target="_blank"><i className="fa fa-telegram"></i></a></li>
                      <li> <a href="https://github.com/KMPARDS" target="_blank"><i className="fa fa-github"></i></a></li>
                      <li><a href="https://www.reddit.com/user/EraSwap" target="_blank"><i className="fa fa-reddit"></i></a> </li>
                      <li><a href="https://www.youtube.com/channel/UCGCP4f5DF1W6sbCjS6y3T1g?view_as=subscriber" target="_blank"><i className="fa fa-youtube"></i></a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3 footers-two">
                  <h5>Other links </h5>
                  <ul className="list-unstyled">
                    <li><a href="/">Home</a></li>
                    <li><a href="">Raise Dispute</a></li>
                    <li><a href="">Dashboard </a></li>
                    <li><a href="">Become a Curator </a></li>
                    <li><a href="">Get 1DAAP</a></li>
                  </ul>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3 footers-three">
                  <h5>Quik Links </h5>
                  <ul className="list-unstyled">
                    <li><a target="_blank" href="http://eraswaptoken.io/pdf/eraswap_whitepaper.pdf">ES White Paper</a></li>
                    <li><a target="_blank" href="http://eraswaptoken.io/pdf/era-swap-howey-test-letter-august7-2018.pdf">Howey Test</a></li>
                    <li><a target="_blank" href="http://eraswaptoken.io/pdf/es-statuary-warning.pdf">Statuary Warning</a></li>
                    <li><a target="_blank" href="pdf/terms-condition.pdf">Terms of Use</a></li>
                    <li><a target="_blank" href="pdf/privacy-policy.pdf">Privacy Policy</a></li>
                    <li><a target="_blank" href="about.htmhttp://eraswaptoken.io/pdf/es-statuary-warning.pdf">Era Swap Terms & Conditins</a></li>
                  </ul>
                </div>


              </div>
            </div>
          </section>
          <section className="disclaimer border">
            <div className="container">
              <div className="row ">
                <div className="col-md-12 py-2">
                  <small>
                    Disclaimer: Element Limited is only an intermediary offering its platform to facilitate the transactions between Seller and Customer/Buyer/User and is not and cannot be a party to or control in any manner any transactions between the Seller and the Customer/Buyer/User. All the offers and discounts on this Website have been extended by various Builder(s)/Developer(s) who have advertised their products. Element is only communicating the offers and not selling or rendering any of those products or services. It neither warrants nor is it making any representations with respect to offer(s) made on the site. Element Limited shall neither be responsible nor liable to mediate or resolve any disputes or disagreements between the Customer/Buyer/User and the Seller and both Seller and Customer/Buyer/User shall settle all such disputes without involving Element Limited in any manner.
               </small>
                </div>
              </div>
            </div>
          </section>
          <section className="copyright border">
            <div className="container">
              <div className="row text-center">
                <div className="col-md-12 pt-3">
                  <p className="copyright">© Eraswap 2020 | All rights reserved</p>
                </div>
              </div>
            </div>
          </section>

        </div>


      </div>
    );
  }
}
