import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './UserDashboard.css';
import { Col, Button, Container, Row } from 'react-bootstrap';
import Rater from 'react-rater';
import 'react-rater/lib/react-rater.css';
import Modal from 'react-bootstrap/Modal';
type State = {
rate: string;
showModal: boolean;
rateModal: boolean;
};
export class UserDashboard extends Component
<State>
{
state: State = {
rate: '',
showModal: false,
rateModal: false,
};
handleClose = () => {
this.setState({
showModal: false,
})
}
render() {
return (
<div>
   <div className="wrapper-container pdt80">
      <section className="faithminus-section1">
         <Container className="pdt60 pdb60">
            <Row>
               <Col sm={12} className="project-tab">
               <div className="progress-outer">
                  <div className="progress">
                     <div className="progress-bar progress-bar-success progress-bar-striped active" ></div>
                     <div className="progress-value">80%</div>
                  </div>
               </div>
               <nav>
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                     <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-dashboard" role="tab" aria-controls="nav-dashboard" aria-selected="true">My DashBoard</a>
                     <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-disputes" role="tab" aria-controls="nav-disputes" aria-selected="false">Requested Disputes</a>
                     <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-act-dispute" role="tab" aria-controls="nav-act-dispute" aria-selected="false">Active Disputes</a>
                     <a className="nav-item nav-link" id="nav-com-tab" data-toggle="tab" href="#nav-com-dispute" role="tab" aria-controls="nav-com-dispute" aria-selected="false">Completed Disputes</a>
                     <a className="nav-item nav-link" id="nav-can-tab" data-toggle="tab" href="#nav-can-dispute" role="tab" aria-controls="nav-can-dispute" aria-selected="false">Cancelled Disputes</a>
                  </div>
               </nav>
               <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-dashboard" role="tabpanel" aria-labelledby="nav-home-tab">
                     <Row className="mt20">
                        <Col sm={12} className="">
                        <h5>Overall Curators Ranking</h5>
                        </Col>
                     </Row>
                     <Row className="">
                        <Col sm={4} className="">
                        <div className="table-responsive mt10">
                           <table className="table">
                              <thead>
                                 <tr>
                                    <th scope="col">Rank</th>
                                    <th scope="col">Curator Name</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr>
                                    <th scope="row">1</th>
                                    <td>Amit Kumar</td>
                                 </tr>
                                 <tr>
                                    <th scope="row">1</th>
                                    <td>Amit Kumar</td>
                                 </tr>
                                 <tr>
                                    <th scope="row">1</th>
                                    <td>Amit Kumar</td>
                                 </tr>
                                 <tr>
                                    <th scope="row">1</th>
                                    <td>Amit Kumar</td>
                                 </tr>
                                 <tr>
                                    <th scope="row">1</th>
                                    <td>Amit Kumar</td>
                                 </tr>
                                 <tr>
                                    <th scope="row">1</th>
                                    <td>Amit Kumar</td>
                                 </tr>
                                 <tr>
                                    <th scope="row">1</th>
                                    <td>Amit Kumar</td>
                                 </tr>
                                 <tr>
                                    <th scope="row">1</th>
                                    <td>Amit Kumar</td>
                                 </tr>
                                 <tr>
                                    <th scope="row">1</th>
                                    <td>Amit Kumar</td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                        </Col>
                        <Col sm={8} className="">
                        <Row>
                           <Col sm={6} className="">
                           <div className="card card-without-border">
                              <ul className="list-group list-group-flush ">
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                    Total ES Earned : 
                                    <span className="badge badge-primary badge-pill">1259 ES</span>
                                 </li>
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                    Stakings Available : 
                                    <span className="badge badge-primary badge-pill">69</span>
                                 </li>
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                    Incentive Received : 
                                    <span className="badge badge-primary badge-pill">160 ES</span>
                                 </li>
                              </ul>
                           </div>
                           </Col>
                           <Col sm={6} className="">
                           <div className="card card-without-border">
                              <ul className="list-group list-group-flush ">
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                    Total Cases: 
                                    <span className="badge badge-primary badge-pill">35</span>
                                 </li>
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                    Solved Cases : 
                                    <span className="badge badge-primary badge-pill">69</span>
                                 </li>
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                    Pending Cases : 
                                    <span className="badge badge-primary badge-pill">5</span>
                                 </li>
                              </ul>
                           </div>
                           </Col>
                        </Row>
                        <Row>
                           <Col sm={6} className="mt20">
                           <div className="card text-white bg-danger  card-without-border">
                              <ul className="list-group list-group-flush ">
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                    Penalties : 
                                    <span className="badge badge-light badge-pill">02</span>
                                 </li>
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                    Warnings Issued : 
                                    <span className="badge badge-light badge-pill">03</span>
                                 </li>
                              </ul>
                           </div>
                           </Col>
                           <Col sm={6} className="mt20">
                           <div className="card card-without-border">
                              <ul className="list-group list-group-flush ">
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                    Total ES Earned : 1
                                    <span className="badge badge-primary badge-pill">1259 ES</span>
                                 </li>
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                    Stakings Available : 
                                    <span className="badge badge-primary badge-pill">69</span>
                                 </li>
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                    Incentive Received : 
                                    <span className="badge badge-primary badge-pill">160 ES</span>
                                 </li>
                              </ul>
                           </div>
                           </Col>
                        </Row>
                        </Col>
                     </Row>
                  </div>


                  <div className="tab-pane fade" id="nav-disputes" role="tabpanel" aria-labelledby="nav-profile-tab">
                     <Row className="mt20">
                        <Col sm={4} className="">
                        <div className="card card-without-border">
                           <ul className="list-group list-group-flush ">
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Title : 
                                 <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Disputed on :
                                 <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Total Curators required :
                                 <span className="badge badge-primary badge-pill">3</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Curators Invloved : 
                                 <span className="badge badge-primary badge-pill">2</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 <h6>Level 2 Dispute</h6>
                              </li>
                           </ul>
                           <div className="col-md-12 mt10 mb10">
                              <Row>
                                 <Col sm={6}>
                                 <a href="" className="btn btn-success btn-block  text-white">Accept</a>
                                 </Col>
                                 <Col sm={6}>
                                 <a href="" className="btn btn btn-outline-danger btn-block ">Decline</a></Col>
                              </Row>
                              <a href="" className="btn btn-primary btn-block mt10 mb10 text-white">View More</a>
                           </div>
                        </div>
                        </Col>
                       <Col sm={4} className="">
                        <div className="card card-without-border">
                           <ul className="list-group list-group-flush ">
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Title : 
                                 <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Disputed on :
                                 <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Total Curators required :
                                 <span className="badge badge-primary badge-pill">3</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Curators Invloved : 
                                 <span className="badge badge-primary badge-pill">2</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 <h6>Level 2 Dispute</h6>
                              </li>
                           </ul>
                           <div className="col-md-12 mt10 mb10">
                              <Row>
                                 <Col sm={6}>
                                 <a href="" className="btn btn-success btn-block  text-white">Accept</a>
                                 </Col>
                                 <Col sm={6}>
                                 <a href="" className="btn btn btn-outline-danger btn-block ">Decline</a></Col>
                              </Row>
                              <a href="" className="btn btn-primary btn-block mt10 mb10 text-white">View More</a>
                           </div>
                        </div>
                        </Col>
                        <Col sm={4} className="">
                        <div className="card card-without-border">
                           <ul className="list-group list-group-flush ">
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Title : 
                                 <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Disputed on :
                                 <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Total Curators required :
                                 <span className="badge badge-primary badge-pill">3</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Curators Invloved : 
                                 <span className="badge badge-primary badge-pill">2</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 <h6>Level 2 Dispute</h6>
                              </li>
                           </ul>
                           <div className="col-md-12 mt10 mb10">
                              <Row>
                                 <Col sm={6}>
                                 <a href="" className="btn btn-success btn-block  text-white">Accept</a>
                                 </Col>
                                 <Col sm={6}>
                                 <a href="" className="btn btn btn-outline-danger btn-block ">Decline</a></Col>
                              </Row>
                              <a href="" className="btn btn-primary btn-block mt10 mb10 text-white">View More</a>
                           </div>
                        </div>
                        </Col>
                     </Row>
                  </div>


                  <div className="tab-pane fade" id="nav-act-dispute" role="tabpanel" aria-labelledby="nav-contact-tab">
                      <Row className="mt20">
                        <Col sm={4} className="">
                        <div className="card card-without-border">
                           <ul className="list-group list-group-flush ">
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Title : 
                                 <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Disputed on :
                                 <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                              </li>
                               <li className="list-group-item d-flex justify-content-between align-items-center">
                                 <h6>Level 2 Dispute</h6>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Turn around Time:
                                 <span className="badge badge-primary badge-pill">24-48 hours</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                Time Left :
                                 <span className="badge badge-primary badge-pill">3hrs 25 mins</span>
                              </li>
                             
                           </ul>
                           <div className="col-md-12 mt10 mb10">
                              <a href="" className="btn btn-primary btn-block mt10 mb10 text-white">View More</a>
                           </div>
                        </div>
                        </Col>
                        <Col sm={4} className="">
                        <div className="card card-without-border">
                           <ul className="list-group list-group-flush ">
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Title : 
                                 <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Disputed on :
                                 <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                              </li>
                               <li className="list-group-item d-flex justify-content-between align-items-center">
                                 <h6>Level 2 Dispute</h6>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Turn around Time:
                                 <span className="badge badge-primary badge-pill">24-48 hours</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                Time Left :
                                 <span className="badge badge-primary badge-pill">3hrs 25 mins</span>
                              </li>
                             
                           </ul>
                           <div className="col-md-12 mt10 mb10">
                              <a href="" className="btn btn-primary btn-block mt10 mb10 text-white">View More</a>
                           </div>
                        </div>
                        </Col>
                         <Col sm={4} className="">
                        <div className="card card-without-border">
                           <ul className="list-group list-group-flush ">
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Title : 
                                 <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Disputed on :
                                 <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                              </li>
                               <li className="list-group-item d-flex justify-content-between align-items-center">
                                 <h6>Level 2 Dispute</h6>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                 Turn around Time:
                                 <span className="badge badge-primary badge-pill">24-48 hours</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                Time Left :
                                 <span className="badge badge-primary badge-pill">3hrs 25 mins</span>
                              </li>
                             
                           </ul>
                           <div className="col-md-12 mt10 mb10">
                              <a href="" className="btn btn-primary btn-block mt10 mb10 text-white">View More</a>
                           </div>
                        </div>
                        </Col>
                     </Row>
                  </div>



                  <div className="tab-pane fade" id="nav-com-dispute" role="tabpanel" aria-labelledby="nav-contact-tab">
                      <Row className="mt20">
                        <Col sm={4} className="">
                          <div className="card card-without-border">
                             <ul className="list-group list-group-flush ">
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Title : 
                                   <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Disputed on :
                                   <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                </li>
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                   <h6>Level 2 Dispute</h6>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Date:
                                   <span className="badge badge-primary badge-pill">24-48 hours</span>
                                </li>
                              
                               
                             </ul>
                             <div className="col-md-12 mt10 mb10">
                                <a href="" className="btn btn-primary btn-block mt10 mb10 text-white">Resolution Successful</a>
                             </div>
                          </div>
                        </Col>
                        <Col sm={4} className="">
                          <div className="card card-without-border">
                             <ul className="list-group list-group-flush ">
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Title : 
                                   <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Disputed on :
                                   <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                </li>
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                   <h6>Level 2 Dispute</h6>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Date:
                                   <span className="badge badge-primary badge-pill">24-48 hours</span>
                                </li>
                              
                               
                             </ul>
                             <div className="col-md-12 mt10 mb10">
                                <a href="" className="btn btn-danger btn-block mt10 mb10 text-white">Resolution Failed</a>
                             </div>
                          </div>
                        </Col>
                         <Col sm={4} className="">
                          <div className="card card-without-border">
                             <ul className="list-group list-group-flush ">
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Title : 
                                   <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Disputed on :
                                   <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                </li>
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                   <h6>Level 2 Dispute</h6>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Date:
                                   <span className="badge badge-primary badge-pill">24-48 hours</span>
                                </li>
                              
                               
                             </ul>
                             <div className="col-md-12 mt10 mb10">
                                <a href="" className="btn btn-primary btn-block mt10 mb10 text-white">Resolution Successful</a>
                             </div>
                          </div>
                        </Col>
                        <Col sm={4} className="">
                          <div className="card card-without-border">
                             <ul className="list-group list-group-flush ">
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Title : 
                                   <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Disputed on :
                                   <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                </li>
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                   <h6>Level 2 Dispute</h6>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Date:
                                   <span className="badge badge-primary badge-pill">24-48 hours</span>
                                </li>
                              
                               
                             </ul>
                             <div className="col-md-12 mt10 mb10">
                                <a href="" className="btn btn-primary btn-block mt10 mb10 text-white">Resolution Successful</a>
                             </div>
                          </div>
                        </Col>
                        <Col sm={4} className="">
                          <div className="card card-without-border">
                             <ul className="list-group list-group-flush ">
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Title : 
                                   <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Disputed on :
                                   <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                </li>
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                   <h6>Level 2 Dispute</h6>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Date:
                                   <span className="badge badge-primary badge-pill">24-48 hours</span>
                                </li>
                              
                               
                             </ul>
                             <div className="col-md-12 mt10 mb10">
                                <a href="" className="btn btn-danger btn-block mt10 mb10 text-white">Resolution Failed</a>
                             </div>
                          </div>
                        </Col>
                         <Col sm={4} className="">
                          <div className="card card-without-border">
                             <ul className="list-group list-group-flush ">
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Title : 
                                   <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Disputed on :
                                   <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                </li>
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                   <h6>Level 2 Dispute</h6>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Date:
                                   <span className="badge badge-primary badge-pill">24-48 hours</span>
                                </li>
                              
                               
                             </ul>
                             <div className="col-md-12 mt10 mb10">
                                <a href="" className="btn btn-primary btn-block mt10 mb10 text-white">Resolution Successful</a>
                             </div>
                          </div>
                        </Col>
                     </Row>
                  </div>



                  <div className="tab-pane fade" id="nav-can-dispute" role="tabpanel" aria-labelledby="nav-contact-tab">
                     <Row className="mt20">
                        <Col sm={4} className="">
                          <div className="card card-without-border">
                             <ul className="list-group list-group-flush ">
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Title : 
                                   <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Disputed on :
                                   <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                </li>
                                 <li className="list-group-item d-flex justify-content-between align-items-center">
                                   <h6>Level 2 Dispute</h6>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                   Date:
                                   <span className="badge badge-primary badge-pill">24-48 hours</span>
                                </li>
                              
                               
                             </ul>
                            
                          </div>
                        </Col>
                     </Row>
                  </div>
                  
               </div>
               </Col>
            </Row>
         </Container>
      </section>
  </div>
  
</div>
);
}
}