import React, { Component } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';





export class Navbar extends Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light navbar-transparent">
          <a className="navbar-brand" href="#"><img src="./images/faithminuslogo.png" width="50" height="50" className="d-inline-block align-top brand" alt="" /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a className="nav-link left-nav" href="/">Home <span className="sr-only">(current)</span></a>
              </li>
              <li className="nav-item active">
                <a className="nav-link left-nav" href="/join-court">Joint Court<span className="sr-only">(current)</span></a>
              </li>
              <li className="nav-item active">
                <a className="nav-link left-nav" href="/user-dashboard">DASHBOARD<span className="sr-only">(current)</span></a>
              </li>

              <li className="nav-item active">
                <a className="nav-link left-nav" href="#">BECOME A CURATOR<span className="sr-only">(current)</span></a>
              </li>
            </ul>
            <ul className="navbar-nav navbar-right">
              <li className="nav-item dropdown ">
                <a className="nav-link dropdown-toggle left-nav" data-toggle="dropdown"
                  role="button" aria-haspopup="true" aria-expanded="false">
                  Alerts</a>
                <div className="dropdown-menu  dropdown-menu-right">
                  <a className="dropdown-item" href="#!"><div className="alrt-flex"><img className="alrt-user" src="./images/user.png" /> <p>Nathan Jones</p></div></a>
                  <a className="dropdown-item " href="#!">Dispute resolution submitted cascode: 1502</a>
                  <a className="dropdown-item " href="#!">Dispute resolution submitted cascode: 1502</a>
                  <a className="dropdown-item " href="#!">Dispute resolution submitted cascode: 1502</a>
                  <a className="dropdown-item " href="#!">Dispute resolution submitted cascode: 1502</a>
                  <a className="dropdown-item " href="#!">Dispute resolution submitted cascode: 1502</a>
                  <a className="dropdown-item view-style" href="#!">View All</a>
                </div>
              </li>
              <li className="nav-item active">
                <a className="nav-link left-nav" href="/user-dashboard">LOGOUT<span className="sr-only">(current)</span></a>
              </li>

            </ul>

          </div>
        </nav>

      </div>
    );
  }
}
