import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {Home}  from './container/Home/Home';
import {Footer} from './components/Footer/Footer';
import {UserDashboard} from './container/UserDashboard/UserDashboard';
import {RequestDispute} from './container/RequestDispute/RequestDispute';
import {Navbar} from './components/Navbar/Navbar';
import {CuratorsList} from './container/CuratorsList/CuratorsList';
import {CuratorsListDetails} from './container/CuratorsListDetails/CuratorsListDetails';
import {CuratorsInvolved} from './container/CuratorsInvolved/CuratorsInvolved';
import {CuratorProceedLevelTwo} from './container/CuratorProceedLevelTwo/CuratorProceedLevelTwo';
import {DisputeStatus} from './container/DisputeStatus/DisputeStatus';
import {JoinCourt} from './container/JoinCourt/JoinCourt';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar/>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/user-dashboard" exact component={UserDashboard} />
          <Route path="/request-dispute" exact component={RequestDispute} />
          <Route path="/curators-list" exact component={CuratorsList} />
          <Route path="/curators-list-details" exact component={CuratorsListDetails} />
          <Route path="/curators-involved" exact component={CuratorsInvolved} />
          <Route path="/curator-proceed-level-two" exact component={CuratorProceedLevelTwo} />
          <Route path="/dispute-status" exact component={DisputeStatus} />
           <Route path="/join-court" exact component={JoinCourt} />
        </Switch>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
