import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './CuratorsListDetails.css';
import { Col, Button, Container, Row } from 'react-bootstrap';
import Rater from 'react-rater';
import 'react-rater/lib/react-rater.css';
import Modal from 'react-bootstrap/Modal';
type State = {
rate: string;
showModal: boolean;
rateModal: boolean;
};
export class CuratorsListDetails extends Component
<State>
{
state: State = {
rate: '',
showModal: false,
rateModal: false,
};
handleClose = () => {
this.setState({
showModal: false,
})
}
render() {
return (
<div>
   <div className="wrapper-container pdt80">
      <section className="faithminus-section1">
         <Container className="pdt60 pdb60">
            <Row>
               <Col sm={12} className="single-page gray-bg">
               <Row className="">
                  <Col md={4} className=" mt10 mb10">
                  <div className="card card-without-border curator-box">
                     <div className="media">
                        <div className="media-left">
                           <a href="#" className="ml10 mr10">
                           <img className="curator-img " src="./images/user.png" alt=""/>
                           </a>
                        </div>
                        <div className="media-body text-center mt10 mr10">
                           <h4 className="media-heading">Jessica Does</h4>
                           <div className="star-rating text-left">
                              <input type="radio" id="5-stars" name="rating" value="5" />
                              <label  className="star">&#9733;</label>
                              <input type="radio" id="4-stars" name="rating" value="4" />
                              <label  className="star">&#9733;</label>
                              <input type="radio" id="3-stars" name="rating" value="3" />
                              <label className="star">&#9733;</label>
                              <input type="radio" id="2-stars" name="rating" value="2" />
                              <label className="star">&#9733;</label>
                              <input type="radio" id="1-star" name="rating" value="1" />
                              <label className="star">&#9733;</label>
                           </div>
                        </div>
                     </div>
                  </div>
                  </Col>
                  <Col md={4} className=" mt10 mb10">
                  <div className="card text-white bg-danger  card-without-border">
                     <ul className="list-group list-group-flush ">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                           Level 1 Cases : 
                           <span className="badge badge-light badge-pill">10</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                           Level 2 Cases
                           <span className="badge badge-light badge-pill">16</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                           Level 3 Cases
                           <span className="badge badge-light badge-pill">09</span>
                        </li>
                     </ul>
                  </div>
                  </Col>
                  <Col md={4} className=" mt10 mb10">
                  <div className="card text-white bg-info  card-without-border">
                     <ul className="list-group list-group-flush ">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                           Total Cases
                           <span className="badge badge-light badge-pill">35</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                           Solved Cases
                           <span className="badge badge-light badge-pill">30</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                           Pending Cases
                           <span className="badge badge-light badge-pill">5</span>
                        </li>
                     </ul>
                  </div>
                  </Col>
               </Row>
               </Col>
               <Col sm={12} className="single-page bg-dark mt40">
               <Row className="">
                  <Col sm={12} className="">
                  <h5 className="text-themecolor">My Charges</h5>
                  </Col>
               </Row>
               <Row className="mt10 ">
                  <Col md={4} className="mt10 mb10 text-white">
                  Level 1 Cases : ES 20
                  </Col>
                  <Col md={4} className=" mt10 mb10 text-white">
                  Level 2 Cases : ES 25
                  </Col>
                  <Col md={4} className=" mt10 mb10 text-white">
                  Level 3 Cases : ES 35
                  </Col>
               </Row>
               </Col>
               <Col sm={12} className="single-page gray-bg mt40">
               <Row className="">
                  <Col sm={12} className="">
                  <h5 className="text-themecolor">Reviews</h5>
                  </Col>
               </Row>
               <Row className="mt10 ">
                  <Col md={6} className=" mt10 mb10 curator-box">
                               <div className="media mb40">
                                 <a className="media-left media-middle ml10 mr10 " href="#">
                                     <img className="curator-img " src="./images/user.png" alt=""/>
                                 </a>

                                 <div className="media-body">
                                   <h6 className="media-heading font-weight-bold">Jessica Does</h6>
                                    It is a long established fact that a reader will be distracted
                                    by the readable content of a page when looking at its layout.
                                  </div>
                               </div>
                  </Col>
                  <Col md={6} className=" mt10 mb10 curator-box ">
                               <div className="media mb40">
                                 <a className="media-left media-middle ml10 mr10 " href="#">
                                     <img className="curator-img " src="./images/user.png" alt=""/>
                                 </a>

                                 <div className="media-body">
                                   <h6 className="media-heading  font-weight-bold">Jessica Does</h6>
                                    It is a long established fact that a reader will be distracted
                                    by the readable content of a page when looking at its layout.
                                  </div>
                               </div>
                  </Col>
                   <Col md={6} className=" mt10 mb10 curator-box">
                               <div className="media mb40">
                                 <a className="media-left media-middle ml10 mr10 " href="#">
                                     <img className="curator-img " src="./images/user.png" alt=""/>
                                 </a>

                                 <div className="media-body">
                                   <h6 className="media-heading  font-weight-bold">Jessica Does</h6>
                                    It is a long established fact that a reader will be distracted
                                    by the readable content of a page when looking at its layout.
                                  </div>
                               </div>
                  </Col>
                   <Col md={6} className=" mt10 mb10 curator-box">
                               <div className="media mb40">
                                 <a className="media-left media-middle ml10 mr10 " href="#">
                                     <img className="curator-img " src="./images/user.png" alt=""/>
                                 </a>

                                 <div className="media-body">
                                   <h6 className="media-heading  font-weight-bold">Jessica Does</h6>
                                    It is a long established fact that a reader will be distracted
                                    by the readable content of a page when looking at its layout.
                                  </div>
                               </div>
                  </Col>
               </Row>
               </Col> 
            </Row>
         </Container>
      </section>
   </div>




   <div className="modal fade com-modal" id="resolution" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
         <div className="modal-content">
            <div className="modal-header">
               <button type="button" className="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div className="modal-body text-center mt20 mb20">
               <i className="fa fa-check-circle text-themecolor fa-5x" aria-hidden="true"></i>
               <h3 className="text-themecolor">Your resolution is successfully submitted </h3>
            </div>
         </div>
      </div>
   </div>

   <div className="modal fade com-modal" id="disputeaccept" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
         <div className="modal-content">
            <div className="modal-header">
               <button type="button" className="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div className="modal-body text-center mt20 mb20">
               <i className="fa fa-check-circle text-themecolor fa-5x" aria-hidden="true"></i>
               <h3 className="text-themecolor">The Dispute is <br/>Successfully Accepted </h3>
            </div>
         </div>
      </div>
   </div>





</div>
);
}
}