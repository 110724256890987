import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './CuratorsList.css';
import { Col, Button, Container, Row } from 'react-bootstrap';
import Rater from 'react-rater';
import 'react-rater/lib/react-rater.css';
import Modal from 'react-bootstrap/Modal';
type State = {
rate: string;
showModal: boolean;
rateModal: boolean;
};
export class CuratorsList extends Component
<State>
{
state: State = {
rate: '',
showModal: false,
rateModal: false,
};
handleClose = () => {
this.setState({
showModal: false,
})
}
render() {
return (
<div>
   <div className="wrapper-container pdt80">
      <section className="faithminus-section1">
         <Container className="pdt60 pdb60">
            <Row>
               <Col sm={12} className="single-page gray-bg">
               <Row className="mt10">
                  <Col md={12} className="">
                  <h5 className="text-themecolor">Curator's List</h5>
                  </Col>
               </Row>
               <Row className="mt10">
                  <Col md={3} className=" mt10 mb10">
                  <div className="card card-without-border text-center curator-box">
                     <img className="curator-img" src="./images/user.png" alt=""/>
                     <h6 className="text-themecolor">Jessica Does </h6>
                     <div className="star-rating">
                        <input type="radio" id="5-stars" name="rating" value="5" />
                        <label  className="star">&#9733;</label>
                        <input type="radio" id="4-stars" name="rating" value="4" />
                        <label  className="star">&#9733;</label>
                        <input type="radio" id="3-stars" name="rating" value="3" />
                        <label className="star">&#9733;</label>
                        <input type="radio" id="2-stars" name="rating" value="2" />
                        <label className="star">&#9733;</label>
                        <input type="radio" id="1-star" name="rating" value="1" />
                        <label className="star">&#9733;</label>
                     </div>
                     <a href="" className="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#resolution">View Profile</a>
                  </div>
                  </Col>
                   <Col md={3} className=" mt10 mb10">
                  <div className="card card-without-border text-center curator-box">
                     <img className="curator-img" src="./images/user.png" alt=""/>
                     <h6 className="text-themecolor">Jessica Does </h6>
                     <div className="star-rating">
                        <input type="radio" id="5-stars" name="rating" value="5" />
                        <label  className="star">&#9733;</label>
                        <input type="radio" id="4-stars" name="rating" value="4" />
                        <label  className="star">&#9733;</label>
                        <input type="radio" id="3-stars" name="rating" value="3" />
                        <label className="star">&#9733;</label>
                        <input type="radio" id="2-stars" name="rating" value="2" />
                        <label className="star">&#9733;</label>
                        <input type="radio" id="1-star" name="rating" value="1" />
                        <label className="star">&#9733;</label>
                     </div>
                     <a href="" className="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#resolution">View Profile</a>
                  </div>
                  </Col>
                   <Col md={3} className=" mt10 mb10">
                  <div className="card card-without-border text-center curator-box">
                     <img className="curator-img" src="./images/user.png" alt=""/>
                     <h6 className="text-themecolor">Jessica Does </h6>
                     <div className="star-rating">
                        <input type="radio" id="5-stars" name="rating" value="5" />
                        <label  className="star">&#9733;</label>
                        <input type="radio" id="4-stars" name="rating" value="4" />
                        <label  className="star">&#9733;</label>
                        <input type="radio" id="3-stars" name="rating" value="3" />
                        <label className="star">&#9733;</label>
                        <input type="radio" id="2-stars" name="rating" value="2" />
                        <label className="star">&#9733;</label>
                        <input type="radio" id="1-star" name="rating" value="1" />
                        <label className="star">&#9733;</label>
                     </div>
                     <a href="" className="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#resolution">View Profile</a>
                  </div>
                  </Col>
                  <Col md={3} className=" mt10 mb10">
                  <div className="card card-without-border text-center curator-box">
                     <img className="curator-img" src="./images/user.png" alt=""/>
                     <h6 className="text-themecolor">Jessica Does </h6>
                     <div className="star-rating">
                        <input type="radio" id="5-stars" name="rating" value="5" />
                        <label  className="star">&#9733;</label>
                        <input type="radio" id="4-stars" name="rating" value="4" />
                        <label  className="star">&#9733;</label>
                        <input type="radio" id="3-stars" name="rating" value="3" />
                        <label className="star">&#9733;</label>
                        <input type="radio" id="2-stars" name="rating" value="2" />
                        <label className="star">&#9733;</label>
                        <input type="radio" id="1-star" name="rating" value="1" />
                        <label className="star">&#9733;</label>
                     </div>
                     <a href="" className="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#resolution">View Profile</a>
                  </div>
                  </Col>
                   <Col md={3} className=" mt10 mb10">
                  <div className="card card-without-border text-center curator-box">
                     <img className="curator-img" src="./images/user.png" alt=""/>
                     <h6 className="text-themecolor">Jessica Does </h6>
                     <div className="star-rating">
                        <input type="radio" id="5-stars" name="rating" value="5" />
                        <label  className="star">&#9733;</label>
                        <input type="radio" id="4-stars" name="rating" value="4" />
                        <label  className="star">&#9733;</label>
                        <input type="radio" id="3-stars" name="rating" value="3" />
                        <label className="star">&#9733;</label>
                        <input type="radio" id="2-stars" name="rating" value="2" />
                        <label className="star">&#9733;</label>
                        <input type="radio" id="1-star" name="rating" value="1" />
                        <label className="star">&#9733;</label>
                     </div>
                     <a href="" className="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#resolution">View Profile</a>
                  </div>
                  </Col>
                   <Col md={3} className=" mt10 mb10">
                  <div className="card card-without-border text-center curator-box">
                     <img className="curator-img" src="./images/user.png" alt=""/>
                     <h6 className="text-themecolor">Jessica Does </h6>
                     <div className="star-rating">
                        <input type="radio" id="5-stars" name="rating" value="5" />
                        <label  className="star">&#9733;</label>
                        <input type="radio" id="4-stars" name="rating" value="4" />
                        <label  className="star">&#9733;</label>
                        <input type="radio" id="3-stars" name="rating" value="3" />
                        <label className="star">&#9733;</label>
                        <input type="radio" id="2-stars" name="rating" value="2" />
                        <label className="star">&#9733;</label>
                        <input type="radio" id="1-star" name="rating" value="1" />
                        <label className="star">&#9733;</label>
                     </div>
                     <a href="" className="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#resolution">View Profile</a>
                  </div>
                  </Col>
                   <Col md={3} className=" mt10 mb10">
                  <div className="card card-without-border text-center curator-box">
                     <img className="curator-img" src="./images/user.png" alt=""/>
                     <h6 className="text-themecolor">Jessica Does </h6>
                     <div className="star-rating">
                        <input type="radio" id="5-stars" name="rating" value="5" />
                        <label  className="star">&#9733;</label>
                        <input type="radio" id="4-stars" name="rating" value="4" />
                        <label  className="star">&#9733;</label>
                        <input type="radio" id="3-stars" name="rating" value="3" />
                        <label className="star">&#9733;</label>
                        <input type="radio" id="2-stars" name="rating" value="2" />
                        <label className="star">&#9733;</label>
                        <input type="radio" id="1-star" name="rating" value="1" />
                        <label className="star">&#9733;</label>
                     </div>
                     <a href="" className="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#resolution">View Profile</a>
                  </div>
                  </Col>
               </Row>
               </Col>
            </Row>
         </Container>
      </section>
   </div>
   <div className="modal fade com-modal" id="resolution" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
         <div className="modal-content">
            <div className="modal-header">
               <button type="button" className="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div className="modal-body text-center mt20 mb20">
               <i className="fa fa-check-circle text-themecolor fa-5x" aria-hidden="true"></i>
               <h3 className="text-themecolor">Your resolution is successfully submitted </h3>
            </div>
         </div>
      </div>
   </div>
   <div className="modal fade com-modal" id="disputeaccept" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
         <div className="modal-content">
            <div className="modal-header">
               <button type="button" className="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div className="modal-body text-center mt20 mb20">
               <i className="fa fa-check-circle text-themecolor fa-5x" aria-hidden="true"></i>
               <h3 className="text-themecolor">The Dispute is <br/>Successfully Accepted </h3>
            </div>
         </div>
      </div>
   </div>
</div>
);
}
}