import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './DisputeStatus.css';
import { Col, Button, Container, Row } from 'react-bootstrap';
import Rater from 'react-rater';
import 'react-rater/lib/react-rater.css';
import Modal from 'react-bootstrap/Modal';
type State = {
rate: string;
showModal: boolean;
rateModal: boolean;
};
export class DisputeStatus extends Component
<State>
{
state: State = {
rate: '',
showModal: false,
rateModal: false,
};
handleClose = () => {
this.setState({
showModal: false,
})
}
render() {
return (
<div>
   <div className="wrapper-container pdt80">
      <section className="faithminus-section1">
         <Container className="pdt60 pdb60">
            <Row>
               <Col sm={12} className="project-tab">
               
               <nav>
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                     <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#active-dispute" role="tab" aria-controls="active-dispute" aria-selected="true">Active Disputes</a>
                     <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#pending-disputes" role="tab" aria-controls="pending-disputes" aria-selected="false">Pending Disputes</a>
                     <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#complete-dispute" role="tab" aria-controls="complete-dispute" aria-selected="false">Completed Disputes</a>
                     
                  </div>
               </nav>
               <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="active-dispute" role="tabpanel" aria-labelledby="nav-home-tab">
                     <Row className="mt20">
                        <Col md={4} className="">
                              <div className="card card-without-border">
                                 <ul className="list-group list-group-flush ">
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Title : 
                                       <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Disputed on :
                                       <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Created On :
                                       <span className="badge badge-primary badge-pill"> 23-05-2020</span>
                                    </li>

                                     <li className="list-group-item d-flex justify-content-between align-items-center">
                                       <h6 className="text-themecolor">Level 1 Dispute</h6>
                                    </li>
                                   
                                   
                                 </ul>
                                 <div className="col-md-12 mt10 mb10">
                                    <a href="" className="btn btn-outline-primary mt10 mb10">View More</a>
                                 </div>
                              </div>
                        </Col>
                         <Col md={4} className="">
                              <div className="card card-without-border">
                                 <ul className="list-group list-group-flush ">
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Title : 
                                       <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Disputed on :
                                       <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Created On :
                                       <span className="badge badge-primary badge-pill"> 23-05-2020</span>
                                    </li>

                                     <li className="list-group-item d-flex justify-content-between align-items-center">
                                       <h6 className="text-themecolor">Level 1 Dispute</h6>
                                    </li>
                                   
                                   
                                 </ul>
                                 <div className="col-md-12 mt10 mb10">
                                    <a href="" className="btn btn-outline-primary mt10 mb10">View More</a>
                                 </div>
                              </div>
                        </Col>
                         <Col md={4} className="">
                              <div className="card card-without-border">
                                 <ul className="list-group list-group-flush ">
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Title : 
                                       <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Disputed on :
                                       <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Created On :
                                       <span className="badge badge-primary badge-pill"> 23-05-2020</span>
                                    </li>

                                     <li className="list-group-item d-flex justify-content-between align-items-center">
                                       <h6 className="text-themecolor">Level 1 Dispute</h6>
                                    </li>
                                   
                                   
                                 </ul>
                                 <div className="col-md-12 mt10 mb10">
                                    <a href="" className="btn btn-outline-primary mt10 mb10">View More</a>
                                 </div>
                              </div>
                        </Col>
                     </Row>
                  </div>


                  <div className="tab-pane fade" id="pending-disputes" role="tabpanel" aria-labelledby="nav-profile-tab">
                     <Row className="mt20">
                        <Col sm={4} className="">
                              <div className="card card-without-border">
                                 <ul className="list-group list-group-flush ">
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Title : 
                                       <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Disputed on :
                                       <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Created On :
                                       <span className="badge badge-primary badge-pill"> 23-05-2020</span>
                                    </li>

                                     <li className="list-group-item d-flex justify-content-between align-items-center">
                                       <h6 className="text-themecolor">Level 2 Dispute</h6>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Total Curators required:
                                       <span className="badge badge-primary badge-pill">3</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                      Curators Invloved :
                                       <span className="badge badge-primary badge-pill">2</span>
                                    </li>
                                   
                                 </ul>
                                 <div className="col-md-12 mt10 mb10">
                                    <a href="" className="btn btn-primary btn-block mt10 mb10 text-white">Pending</a>
                                 </div>
                              </div>
                        </Col>
                         <Col sm={4} className="">
                              <div className="card card-without-border">
                                 <ul className="list-group list-group-flush ">
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Title : 
                                       <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Disputed on :
                                       <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Created On :
                                       <span className="badge badge-primary badge-pill"> 23-05-2020</span>
                                    </li>

                                     <li className="list-group-item d-flex justify-content-between align-items-center">
                                       <h6 className="text-themecolor">Level 2 Dispute</h6>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Total Curators required:
                                       <span className="badge badge-primary badge-pill">3</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                      Curators Invloved :
                                       <span className="badge badge-primary badge-pill">2</span>
                                    </li>
                                   
                                 </ul>
                                 <div className="col-md-12 mt10 mb10">
                                    <a href="" className="btn btn-primary btn-block mt10 mb10 text-white">Pending</a>
                                 </div>
                              </div>
                        </Col>
                          <Col sm={4} className="">
                              <div className="card card-without-border">
                                 <ul className="list-group list-group-flush ">
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Title : 
                                       <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Disputed on :
                                       <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Created On :
                                       <span className="badge badge-primary badge-pill"> 23-05-2020</span>
                                    </li>

                                     <li className="list-group-item d-flex justify-content-between align-items-center">
                                       <h6 className="text-themecolor">Level 2 Dispute</h6>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Total Curators required:
                                       <span className="badge badge-primary badge-pill">3</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                      Curators Invloved :
                                       <span className="badge badge-primary badge-pill">2</span>
                                    </li>
                                   
                                 </ul>
                                 <div className="col-md-12 mt10 mb10">
                                    <a href="" className="btn btn-primary btn-block mt10 mb10 text-white">Pending</a>
                                 </div>
                              </div>
                        </Col>
                     </Row>
                  </div>


                  <div className="tab-pane fade" id="complete-dispute" role="tabpanel" aria-labelledby="nav-contact-tab">
                     <Row className="mt20">
                        <Col md={4} className="">
                              <div className="card card-without-border">
                                 <ul className="list-group list-group-flush ">
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Title : 
                                       <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Disputed on :
                                       <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Created On :
                                       <span className="badge badge-primary badge-pill"> 23-05-2020</span>
                                    </li>

                                     <li className="list-group-item d-flex justify-content-between align-items-center">
                                       <h6 className="text-themecolor">Level 1 Dispute</h6>
                                    </li>
                                   
                                   
                                 </ul>
                                 <div className="col-md-12 mt10 mb10">
                                    <a href="" className="btn btn-outline-primary mt10 mb10">View More</a>
                                 </div>
                                    <Row className="mt0">
                                       <Col md={12} className="">
                                       <a href="" className="btn btn-primary btn-block text-left btn-sm text-white" data-toggle="modal" data-target="#resolution">
                                        <small>Not Satisfied ?</small> 
                                        <h6>Click here to proceed to Level 2 <i className="fa fa-long-arrow-right" aria-hidden="true"></i></h6>
                                       </a>
                                       </Col>
                                    </Row>
                              </div>
                        </Col>
                       <Col md={4} className="">
                              <div className="card card-without-border">
                                 <ul className="list-group list-group-flush ">
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Title : 
                                       <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Disputed on :
                                       <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Created On :
                                       <span className="badge badge-primary badge-pill"> 23-05-2020</span>
                                    </li>

                                     <li className="list-group-item d-flex justify-content-between align-items-center">
                                       <h6 className="text-themecolor">Level 1 Dispute</h6>
                                    </li>
                                   
                                   
                                 </ul>
                                 <div className="col-md-12 mt10 mb10">
                                    <a href="" className="btn btn-outline-primary mt10 mb10">View More</a>
                                 </div>
                                    <Row className="mt0">
                                       <Col md={12} className="">
                                       <a href="" className="btn btn-primary btn-block text-left btn-sm text-white" data-toggle="modal" data-target="#resolution">
                                        <small>Not Satisfied ?</small> 
                                        <h6>Click here to proceed to Level 2 <i className="fa fa-long-arrow-right" aria-hidden="true"></i></h6>
                                       </a>
                                       </Col>
                                    </Row>
                              </div>
                        </Col>
                        <Col md={4} className="">
                              <div className="card card-without-border">
                                 <ul className="list-group list-group-flush ">
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Title : 
                                       <span className="badge badge-primary badge-pill">Work Submitted Incomplete</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Disputed on :
                                       <span className="badge badge-primary badge-pill"> TimeSwappers</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                       Created On :
                                       <span className="badge badge-primary badge-pill"> 23-05-2020</span>
                                    </li>

                                     <li className="list-group-item d-flex justify-content-between align-items-center">
                                       <h6 className="text-themecolor">Level 1 Dispute</h6>
                                    </li>
                                   
                                   
                                 </ul>
                                 <div className="col-md-12 mt10 mb10">
                                    <a href="" className="btn btn-outline-primary mt10 mb10">View More</a>
                                 </div>
                                    <Row className="mt0">
                                       <Col md={12} className="">
                                       <a href="" className="btn btn-primary btn-block text-left btn-sm text-white" data-toggle="modal" data-target="#resolution">
                                        <small>Not Satisfied ?</small> 
                                        <h6>Click here to proceed to Level 2 <i className="fa fa-long-arrow-right" aria-hidden="true"></i></h6>
                                       </a>
                                       </Col>
                                    </Row>
                              </div>
                        </Col>
                     </Row>
                  </div>



                  
               </div>
               </Col>
            </Row>
         </Container>
      </section>
  </div>
   {/* Resolution modal  */}
   <Modal show={this.state.showModal} onHide={this.handleClose} className="date-modal-container" >
      <Modal.Header closeButton>
         <Modal.Title id='ModalHeader'></Modal.Title>
         <div className="close-flex-end">
            <img className="mdl-cross" src="./images/cancel.png" onClick={() => this.setState({ showModal: false })} />
         </div>
      </Modal.Header>
      <Modal.Body>
         <div className="prf-flex-box">
            <img className="prf-img-modal" src="./images/imgProfile.jpg" />
            <p className="prf-nm-modal">Jessica Does</p>
         </div>
         <h5>Resolution :</h5>
         <p>There are many variations of passages of Lorem Ipsum available, but the
            majority have suffered alteration in some form, by injected humour, or
            randomised words which don't look even slightly believable. If you are going to
            use a passage of Lorem Ipsum, you need to be sure there isn't anything
            embarrassing hidden in the middle of text. All the Lorem Ipsum generators
            on the Internet tend to repeat predefined chunks as necessary,
         </p>
      </Modal.Body>
   </Modal>
   {/* Rate modal  */}
   <Modal show={this.state.rateModal} onHide={this.handleClose} className="date-modal-container" >
      <Modal.Header closeButton>
         <Modal.Title id='ModalHeader'></Modal.Title>
         <div className="close-flex-end">
            <img className="mdl-cross" src="./images/cancel.png" onClick={() => this.setState({ rateModal: false })} />
         </div>
      </Modal.Header>
      <Modal.Body>
         <div className="prf-flex-box">
            <img className="prf-img-modal" src="./images/imgProfile.jpg" />
            <p className="prf-nm-modal">Jessica Does</p>
         </div>
         <div><input className='modal-rev-field' type="text" id="Title" name="Title" placeholder="" /></div>
         <div className="md-btn-right">
            <button className="submit-btn-md">Submit</button>
         </div>
      </Modal.Body>
   </Modal>
</div>
);
}
}